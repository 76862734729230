import { useState, useCallback, useMemo } from 'react';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useMount } from 'react-use';
import { getFirebaseStore } from '~/modules/SDK/firebase/getFirebase';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
/** FireBase firestore "symbol_watch_list" */
export const useSymbolWatchList = (groupName) => {
    const firestore = getFirebaseStore();
    const { meUserState } = useMeStore();
    const userCode = meUserState?.uid || undefined;
    //firestore自選股 集合:symbol_watch_list */
    const collectionRef = firestore().collection('symbol_watch_list');
    const [symbolList, setSymbolList] = useState();
    /** 群組 */
    const symbolListArray = symbolList?.[groupName];
    /** 獲取自選股清單 */
    const getSymbolList = useCallback(() => {
        if (userCode) {
            collectionRef.doc(userCode).onSnapshot(doc => setSymbolList(doc.data()));
        }
    }, [userCode, collectionRef]);
    /** 第一次使用時創建xxxx_group空文件 */
    const creatValueFile = useCallback(() => {
        if (symbolListArray === undefined && userCode !== undefined) {
            collectionRef
                .doc(userCode)
                .set({
                [groupName]: firestore.FieldValue.arrayUnion(),
            }, { merge: true })
                .then(() => {
                FrGa.event({ 自選股__創建空文件: { groupName } });
            });
        }
    }, [symbolListArray, userCode, collectionRef, groupName, firestore.FieldValue]);
    /** 新增自選股 並設定陣列自選股檔數 */
    const addSymbol = useCallback((symbol, listLength) => {
        if (symbolListArray === undefined ||
            ((symbolListArray?.length ?? 0) < listLength && !symbolListArray?.includes(symbol))) {
            const keep = [...(symbolListArray ?? []), symbol];
            collectionRef
                .doc(userCode)
                .update({
                [groupName]: firestore.FieldValue.arrayUnion(symbol),
            })
                .then(() => {
                FrGa.event({ 自選股__新增自選股: { groupName, target: [symbol] } });
            });
        }
        if (symbolListArray?.includes(symbol))
            alert('自選股清單已有此商品!');
        if ((symbolListArray?.length ?? 0) >= listLength)
            alert('自選股數量已達上限!');
    }, [symbolListArray, collectionRef, userCode, groupName, firestore.FieldValue]);
    /** 移除選取的自選股 並設定陣列自選股檔數 */
    const removeSymbol = useCallback((symbolNumber) => {
        const symbolListRemove = [...symbolListArray];
        const index = symbolListRemove.indexOf(symbolNumber);
        symbolListRemove.splice(index, 1);
        collectionRef
            .doc(userCode)
            .update({
            [groupName]: symbolListRemove,
        })
            .then(() => {
            FrGa.event({
                自選股__移除自選股: {
                    groupName,
                    replace: symbolListRemove,
                    target: symbolListArray || [],
                },
            });
        });
    }, [collectionRef, groupName, symbolListArray, userCode]);
    /** 除全部自選股 並設定陣列自選股檔數 */
    const removeAllSymbol = useCallback(() => {
        if (symbolListArray !== undefined) {
            collectionRef
                .doc(userCode)
                .set({
                [groupName]: [],
            }, { merge: true })
                .then(() => {
                FrGa.event({ 自選股__移除所有自選股: { groupName } });
            });
        }
    }, [collectionRef, groupName, symbolListArray, userCode]);
    useMount(() => {
        getSymbolList();
        creatValueFile();
    });
    return useMemo(() => {
        return {
            state: {
                symbolListArray: symbolListArray || [],
            },
            acts: { addSymbol, removeSymbol, removeAllSymbol, getSymbolList },
        };
    }, [addSymbol, removeSymbol, removeAllSymbol, symbolListArray, getSymbolList]);
};
